<template>
  <v-container fluid v-if="!isLoading">
    <v-form ref="form" v-model="validForm">
      <v-card>
        <v-card-title class="white--text primary py-2" primary-title>
          <div>
            <h3 class="headline mb-0">AQUI EL TITULO</h3>
          </div>
        </v-card-title>
        <v-card-text>
          <!--  BOTONES -->
          <v-row>
            <v-col cols="12">
              <v-btn
                class="float-right"
                color="primary"
                dark
                @click.prevent="guardarDatos"
                >GUARDAR</v-btn
              >
              <v-btn class="float-right mr-2" color="primary" dark
                :to="{name: 'agrup'}"
                >CANCELAR</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <!-- NOMBRE -->
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                clearable
                dense
                v-model="datos.nombre"
                label="Nombre"
                autocomplete="OFF"
                :rules="[(v) => !!v || 'Este campo es requerido']"
                required
              ></v-text-field>
            </v-col>
            <!-- SELECT TIPO DE GUIA -->
            <v-col cols="12" md="3">
              <v-select
                :items="compTipoGuia"
                v-model="datos.tipo_guia_id"
                item-value="id"
                item-text="tipo"
                outlined
                dense
                label="Tipo Guia"
                required
                :rules="[(value) => !!value || 'Escoga un tipo']"
                clearable
              ></v-select>
            </v-col>
            <!-- posicion -->
            <v-col cols="12" md="3">
              <v-text-field
                outlined
                clearable
                dense
                type="number"
                v-model="datos.posicion"
                label="Posicion en Guia"
                autocomplete="OFF"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- COMENTARIOS -->
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                class="mt-5 elevation-2"
                counter="1000"
                :value="datos.descripcion"
                v-model="datos.descripcion"
                required
                clear-icon="mdi-close-circle"
                label="Descripcion"
                rows="5"
                hint="Descripción de agrupación"
                :rules="[(v) => !!v || 'Este campo es requerido']"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Agrupaciones-form",
  data: () => ({
    
    isLoading: true,
    validForm: true,
    datos: {
      id: null,
      nombre: null,
      descripcion: null,
      tipo_guia: null,
      posicion: null,
    },
  }),
  methods: {
      ...mapActions("agrup", ["setNuevaAgrup", "setAgrupById"]),
    async guardarDatos() {
      this.$refs.form.validate();
      if (!this.validForm) {
        return;
      }
      const res = await this.setNuevaAgrup(this.datos);
      console.log(res)     
    },
  },
  computed:{
      ...mapGetters("agrup", ["gtrTipoGuia"]),
      compTipoGuia() {
          if (!this.gtrTipoGuia) return [];

          return this.gtrTipoGuia;
      }
  },
  mounted() {
    this.isLoading = false;
  },
};
</script>

<style scoped></style>
